<template>
  <div class="input_with_paste">
    <input v-bind="$attrs" v-on="{...$listeners, input:event => $emit('input', event.target.value)}" />
    <span class="text_btn" @click="$emit('onPaste')">粘贴</span>
  </div>
</template>

<script>
export default {
  name: 'InputWithPaste',
  methods: {
    clean() {
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.input_with_paste {
  width: 100%;
  height: 3rem;
  padding-bottom: .1rem;
  border-bottom: .5px solid var(--light-gray);
  display: flex;
  align-items: center;
  input {
    flex: auto 1 0;
    width: 50%;
    font-size: 1.2rem;
    border: none;
    margin-left: .3rem;
    font-weight: 600;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color:  var(--light-gray);
    }
  }
  .text_btn {
    flex: 6.5rem 0 0;
    border: none;
    height: 100%;
    color: var(--orange);
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

