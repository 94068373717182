<template>
  <div class="login">
    <!-- <div class="back">
        <img class="icon" src="@/assets/images/login/ic_redeem_return.webp" />
      </div> -->
    <img class="logo" src="@/assets/images/login/ic_log_in_logo.webp" />
    <div class="inputArea">
      <login-input :maxlength="11" type="text" icon="ic_runningpoints_mine.webp" placeholder="请输入手机号" v-model="userMobile" />
      <login-input v-if="isPasswordLogin" type="password" icon="ic_runningpoints_password.webp" placeholder="请输入密码" v-model="password" />
      <login-input v-else type="password" icon="ic_runningpoints_verificationcode.png" :innerBtn="true" placeholder="请输入验证码" v-model="password" :userMobile="userMobile" />
    </div>
    <div class="flex-sa-c mb_5em">
      <div class="textBtn ta_left " @click="changeMode">{{ changeModeLabel }}</div>
      <div class="registerBtn ta_right" @click="gotoRegister()">新户用注册</div>
    </div>

    <button class="btn oval mb_1rem" @click="userLogin">登录</button>
    <div class="textBtn ta_center" @click="$router.push('/bindOTP/resetPassword')">忘记密码？</div>
    <custom-dialog :visible="isVisibleDialog" width="100%" position="bottom" :radius="['top-left', 'top-right']" @onClose="handleDialogClose">
      <template>
        <div class="dialog_header ta_left mb_1em">谷歌验证丢失？<span class="dialog_header textBtn">联系客服</span></div>
      </template>
      <div class="dialog_title ta_left mb_1em">谷歌安全验证</div>
      <div class="divider mb_1em"></div>
      <div class="dialog_msg ta_left mb_2em">谷歌验证码</div>
      <button class="btn oval mb_1rem" @click="bindOTP">下载并绑定</button>
    </custom-dialog>
    <custom-dialog :visible="isVisableSMS" width="100%" position="bottom" :radius="['top-left', 'top-right']" @onClose="handleGoogleLoginDialogClose">
      <div class="tw-flex tw-justify-between">
        <div class="dialog_title ta_left mb_1em">安全验证</div>
      </div>
      <div class="divider mb_1em"></div>
      <div v-if="verifyOrSecurity">
        <div class="tw-flex tw-w-full tw-justify-between  tw-pb-4">
          <div class="tw-w-full tw-flex tw-items-center">
            <img class="tw-w-5" src="@/assets/images/login/ic_runningpoints_verificationcode.png" />
            <input type="text" class="tw-text-base tw-border-0  focus:tw-outline-none tw-w-full" placeholder="请输入验证码" v-model="smsText" />
          </div>

          <button v-if="smsCoolDown == 0" class="innerBtn !tw-cursor-pointer " @click="getSMScode">
            获取验证码
          </button>
          <button v-else class="innerBtn tw-text-black tw-whitespace-nowrap tw-font-normal tw-text-sm">({{ smsCoolDown }}) 后重新发送</button>
        </div>
        <div class="divider mb_1em"></div>
        <div class="tw-flex tw-justify-end">
          <div @click="verifyOrSecurity = false" class="dialog_title ta_left mb_1em tw-mr-1 tw-text-[#ff6a23]">安全密码验证</div>
        </div>
        <button class="btn oval mb_1rem" @click="loginWithSMS">确认</button>
      </div>
      <div v-else>
        <div class="tw-flex tw-w-full tw-justify-between  tw-pb-4">
          <div class="tw-w-full tw-flex tw-items-center">
            <img class="tw-w-5" src="@/assets/images/login/ic_runningpoints_verificationcode.png" />
            <input type="text" class="tw-text-base tw-border-0  focus:tw-outline-none tw-w-full" placeholder="请输入安全密码" v-model="securityText" />
          </div>
        </div>
        <div class="divider mb_1em"></div>
        <div class="tw-flex tw-justify-end">
          <div @click="verifyOrSecurity = true" class="dialog_title ta_left mb_1em tw-mr-1 tw-text-[#ff6a23]">短信安全验证</div>
        </div>
        <button class="btn oval mb_1rem" @click="loginWithSecurity">确认</button>
      </div>    
    </custom-dialog>
    <custom-dialog :visible="isVisibleGoogleLoginDialog" width="100%" position="bottom" :radius="['top-left', 'top-right']" @onClose="handleGoogleLoginDialogClose">
      <template v-slot:header>
        <div class="dialog_header ta_left mb_1em">谷歌验证丢失？<span class="dialog_header textBtn" @click="openKefu">联系客服</span></div>
      </template>
      <div class="dialog_title ta_left mb_1em">谷歌安全验证</div>
      <div class="divider mb_1em"></div>
      <div class="dialog_msg ta_left mb_2em">谷歌验证码</div>
      <input-with-paste type="text" class="mb_1em" placeholder="谷歌验证码" v-model="code" @onPaste="handlePaste" />
      <button class="btn oval mb_1rem" @click="secondLogin">谷歌登入</button>
    </custom-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoginInput from "@/components/LoginInput";
import CustomDialog from "@/components/CustomDialog";
import InputWithPaste from "../components/InputWithPaste";
import cookie from "js-cookie";
import tim from "@/utils/tim";
import * as api from "@/api/message";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Login",
  components: {
    LoginInput,
    CustomDialog,
    InputWithPaste,
  },
  data() {
    return {
      fingerPrintID: "",
      loginMode: true, // 1:密码登录 0:验证码登录
      smsText: "",
      securityText: "",
      userMobile: "",
      password: "",
      verifyCode: "",
      isVisibleDialog: false,
      isVisibleGoogleLoginDialog: false,
      isVisableSMS: false,
      smsCoolDown: 0,
      code: "",
      verifyOrSecurity: true, // true:短信安全驗證 false:安全密碼驗證
    };
  },
  computed: {
    ...mapState("config", ["kefu"]),
    isPasswordLogin() {
      return this.loginMode;
    },
    changeModeLabel() {
      return this.loginMode ? "用验证码登录" : "用密码登录";
    },
  },
  async created() {
    if (this.$route.query.payMoney == 1 && cookie.get("token")) {
      this.$router.push({
        path: "/my/index",
        query: {
          orderNo: this.$route.query.orderNo,
        },
      });
    } else if (cookie.get("token")) {
      this.$router.push({
        path: "/",
      });
    }
  },
  methods: {
    ...mapActions("auth", ["login", "googleLogin", "getLoginSMSCode", "loginSMS"]),
    ...mapActions("message", ["getSignalData"]),
    async loginWithSMS() {
      console.log("lognSMS");

      const deviceNo = localStorage.getItem("deviceNo") || uuidv4();
      localStorage.setItem("deviceNo", deviceNo);

      const data = {
        userMobile: this.userMobile,
        verifyCode: this.smsText,
        device: 2,
        deviceNo,
      };

      this.loginSMS(data).then((res) => {
        console.log(res);
        if (res.code === "ok") this.$router.push("/");
      });
      this.isVisableSMS = false;
    },

    async loginWithSecurity() {
      console.log("lognSecurity");

      const deviceNo = localStorage.getItem("deviceNo") || uuidv4();
      localStorage.setItem("deviceNo", deviceNo);

      const data = {
        userMobile: this.userMobile,
        securityCode: this.securityText,
        device: 2,
        deviceNo,
      };
      
      this.loginSMS(data).then((res) => {
        
        console.log(res);
        if (res.code === "ok") this.$router.push("/");
      });
      this.isVisableSMS = false;
    },

    getSMScode() {
      console.log("getSMScode");
      if (this.smsCoolDown > 0) return;
      this.getLoginSMSCode(this.userMobile).then((res) => {
        if (res.code === "ok") {
          console.log(res);
          this.smsCoolDown = 60;
          const interval = setInterval(() => {
            this.smsCoolDown--;
            if (this.smsCoolDown == 0) {
              clearInterval(interval);
            }
          }, 1000);
        }
      });
    },
    getPlatform() {
      if (/Trident/.test(navigator.userAgent)) {
        return "IE";
      } else if (/Edge/.test(navigator.userAgent)) {
        return "Edge";
      } else if (/Chrome/.test(navigator.userAgent)) {
        return "Chrome";
      } else if (/Firefox/.test(navigator.userAgent)) {
        return "Firefox";
      } else if (/Safari/.test(navigator.userAgent) && /Macintosh/.test(navigator.userAgent)) {
        return "Safari (Mac)";
      } else if (/Safari/.test(navigator.userAgent) && /iPhone|iPad|iPod/.test(navigator.userAgent)) {
        return "Safari (iOS)";
      } else {
        return "Other";
      }
    },

    async userLogin() {
      const cookieUserIdObj = Object.assign({}, cookie.get("userId"));
      const cookieUserId = Object.values(cookieUserIdObj)
        .join("")
        .split("_")
        .pop();
      const deviceNo = localStorage.getItem("deviceNo") || uuidv4();
      localStorage.setItem("deviceNo", deviceNo);

      const data = {
        userMobile: this.userMobile,
        password: "",
        verifyCode: "",
        device: 2,
        deviceNo,
      };

      if (this.loginMode) {
        data.password = this.password;
        delete data.verifyCode;
      } else {
        data.verifyCode = this.password;
        delete data.password;
      }
      try {
        const res = await this.login(data);
        console.log(res);
        if (res.code === "ok") {
          switch (res.data.loginType) {
            case 0:
              // this.openDialog();
              break;
            case 1:
              this.openGoogleLoginDialog();
              break;
            case 2:
              this.openSMSLoginDialog();
              break;

            default:
              break;
          }
          if (res.data.token != "") {
            const token = res.data.token;
            cookie.set("token", token);
            const signalData = await this.getSignalData({ signalNo: token });
            const userID = signalData.data.id;
            const userSig = signalData.data.genSig;

            if (cookieUserId) await api.kickUserTim(cookieUserId);
            cookie.set("userId", userID);
            tim.init({ userID: userID, userSig });
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    gotoRegister() {
      this.$router.push("/bindOTP/register");
    },
    changeMode() {
      this.loginMode = !this.loginMode;
    },
    openDialog() {
      this.isVisibleDialog = true;
    },
    closeDialog() {
      this.isVisibleDialog = false;
    },
    handleDialogClose() {
      this.closeDialog();
    },
    bindOTP() {
      this.closeDialog();
      this.$router.push("/bindOTP");
    },
    handlePaste() {
      navigator.clipboard.readText().then((text) => {
        this.code = text;
      });
    },
    openSMSLoginDialog() {
      this.smsText = "";
      this.isVisableSMS = true;
    },
    openGoogleLoginDialog() {
      this.isVisibleGoogleLoginDialog = true;
    },
    closeGoogleLoginDialog() {
      this.isVisibleGoogleLoginDialog = false;
    },
    handleGoogleLoginDialogClose() {
      this.closeGoogleLoginDialog();
    },
    secondLogin() {
      let data = {
        code: this.code,
        userMobile: this.userMobile,
      };
      this.googleLogin(data).then((res) => {
        if (res.code === "ok") this.$router.push("/");
      });
      this.closeGoogleLoginDialog();
    },
    openKefu() {
      window.open(this.kefu);
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-image: url("../assets/images/login/ic_log_in_bg.png");

  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 1.2em 1.2em 1.2rem 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (min-width: 480px) {
    background-image: unset;
    padding: 1.2em 7.2em 1.2rem 7.2em;
    overflow: scroll;
  }

  // overflow-y: scroll;
  .back {
    width: 100%;
    flex: 8em 0 0;
    text-align: left;

    .icon {
      width: 2rem;
      height: auto;
    }
  }

  .logo {
    width: 7em;
    height: auto;
  }

  .inputArea {
    width: 100%;
    padding-top: 20%;
    margin-bottom: 1rem;

    @media (min-width: 480px) {
      padding-top: 0%;
      margin-bottom: 0rem;
    }

    & > div:first-child {
      margin-bottom: 3rem;

      @media (min-width: 480px) {
        margin-bottom: 1rem;
      }
    }
  }

  .registerBtn {
    width: 100%;
    color: var(--black);
    font-size: 0.8rem;
  }

  .dialog_ {
    &header {
      font-size: 0.9em;
    }

    &title {
      font-size: 1em;
      font-weight: 600;
    }

    &msg {
      font-size: 0.8em;
    }
  }
}

.textBtn {
  @media (min-width: 480px) {
    margin-top: 10px;
  }
}

.flex-sa-c {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.innerBtn {
  flex: 6.5rem 0 0;
  margin-left: 0.5rem;
  border: none;
  border-radius: 5px;
  height: 100%;
  color: var(--orange);
  font-size: 1.1rem;
  font-weight: 600;
  &:active {
    background-color: var(--pink);
  }
}
</style>
