<template>
  <div class="custom_dialog" :class="{visible: visible}" @click="$emit('onClose')">
    <div class="dialog_content" :class="customClass" :style="customStyle" @click.stop="">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
    },
    position: {
      type: String,
      default: 'center'
    },
    radius: {
      type: Array,
      default: () => ['top-left', 'top-right', 'bottom-left', 'bottom-right']
    }
  },
  computed: {
    customStyle() {
      let style = {}
      if(this.width) style.width = this.width
      return style
    },
    customClass() {
      let classes = `${this.position} ${this.radius.join(" ")}`
      return classes
    }
  },
}
</script>

<style lang="scss" scoped>
.custom_dialog {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--transparent-black);
  &.visible {
    display: block;
  }
  .dialog_content {
    max-width: 93vw;
    padding: 1em;
    position: absolute;
    background: white;
    &.left{
      left: 0;
      top: 50%;
    }
    &.right{
      right: 0;
      top: 50%;
    }
    &.top{
      top: 0;
      left: 50%;
      transform: translate(-50%);
    }
    &.bottom{
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
    &.center{
      left: 50%;
      top: 50%;
      transform: translate(-50%);
    }
    &.top-left{
      border-top-left-radius: 16px;
    }
    &.top-right{
      border-top-right-radius: 16px;
    }
    &.bottom-left{
      border-bottom-left-radius: 16px;
    }
    &.bottom-right{
      border-bottom-right-radius: 16px;
    }
    .header {
      border-bottom: 1px solid lightgray;
      margin-bottom: 1em;
    }
  }
}
</style>